import { QueryClientProvider, QueryClient } from 'react-query'
import { CookieBar } from './CookieBar'

const client = new QueryClient()

export default function CookieBarUniversal({ initialConsent, layoutClassName = undefined, content = undefined }) {
  return (
    <QueryClientProvider {...{ client }}>
      <CookieBar {...{ initialConsent, layoutClassName, content }} />
    </QueryClientProvider>
  )
}
