import { useCookieConsent } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConsentEvent } from '/machinery/tracking/cookieConsent'
import { useTranslate } from '/machinery/I18n'
import { useReportError } from '/machinery/ReportError'
import { PortableTextCookieBar } from '/features/buildingBlocks/PortableText'

import { routeMap } from '/routeMap'
import { CtaButtonPrimary, CtaButtonSecondary } from '/features/buildingBlocks/CtaButton'
import { ContainerLg } from '/features/buildingBlocks/Container'
import styles from './CookieBar.css'

export function CookieBar({ initialConsent, layoutClassName = undefined, content }) {
  const clientConfig = useClientConfig()
  const { __ } = useTranslate()
  const reportError = useReportError()

  const {
    consent,
    acceptAll,
    rejectAll,
  } = useCookieConsent({
    availablePermissions: clientConfig.cookieConsent.availablePermissions,
    setCookieEndpoint: routeMap.api.v1.cookieConsent(),
    initialConsent,
    onChange: pushCookieConsent,
    onError: reportError
  })

  const displayConsent = consent?.show ?? true

  return displayConsent ? (
    <aside className={cx(styles.component, layoutClassName)}>
      <ContainerLg>
        <div className={styles.inner}>
          <div className={styles.content}>
            {content && <PortableTextCookieBar value={content} />}
          </div>

          <div className={styles.actions}>
            <CtaButtonSecondary dataX='cookiebar-reject' onClick={rejectAll}>{__`cookie-bar-reject`}</CtaButtonSecondary>
            <CtaButtonPrimary dataX='cookiebar-accept' onClick={acceptAll}>{__`cookie-bar-accept`}</CtaButtonPrimary>
          </div>


        </div>
      </ContainerLg>
    </aside>
  ) : <aside style={{ display: 'none' }} />
}

function pushCookieConsent(consent) {
  pushToDataLayer(cookieConsentEvent(consent))
}
